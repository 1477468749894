import { defineComponent, onMounted, ref } from "vue";

const menu = ref();

export default defineComponent({
  name: "TopMenu",
  props: {
    folder: {
      type: String,
      required: true,
    },
    highlightedIndex: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    onMounted(async () => {
      menu.value = (await import(`@/json/${props.folder}`)).default;
    })

    return {
      menu,
      props,
    };
  },
});
