<style lang="scss" src="./FilterChip.scss"></style>
<script lang="ts" src="./FilterChip.ts"></script>

<template>
  <div
    class="user-select__none display-inline"
    @click="item.value !== '-1' ? null : $emit('onCancelFilter')"
  >
    <q-chip
      :color="props.secondary !== undefined ? '' : 'white margin-bottom-15'"
      :class="`${item.value !== '-1' ? '' : 'cursor-pointer'} ${props.secondary !== undefined ? 'background-color-B5E3FF' : 'q-px-lg border-1-color-B3B3B3'}`"
      :style="props.secondary !== undefined ? {borderRadius: '24px', height: '36px', margin: '0 4px 0 0'} : {}"
    >
      {{ item.title || item.text }}
      <font-awesome-icon
        @click="$emit('onCancelFilter')"
        :class="`q-pl-md cursor-pointer ${props.secondary !== undefined ? '' : 'red'}`"
        :icon="item.value !== '-1' ? 'fal fa-close' : 'fal fa-trash'"
        size="sm"
      />
    </q-chip>
  </div>
</template>
