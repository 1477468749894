export function getEnvImagePath() {
  let envUrl;
  let url = window.location.href;
  if (url.includes("sandbox.")) {
    envUrl = process.env.VUE_APP_IMAGE_URL_SANDBOX;
  } else if (url.includes("tst.")) {
    envUrl = process.env.VUE_APP_IMAGE_URL_TST;
  } else if (url.includes("acc.")) {
    envUrl = process.env.VUE_APP_IMAGE_URL_ACC;
  } else if (url.includes("demo.")) {
    envUrl = process.env.VUE_APP_IMAGE_URL_DEMO;
  } else if (url.includes("localhost")) {
    envUrl = process.env.VUE_APP_IMAGE_URL_TST;
  } else {
    envUrl = process.env.VUE_APP_IMAGE_URL_PRD;
  }
  return envUrl;
}

export function getEnvProductImagePath() {
  let envUrl;
  let url = window.location.href;
  if (url.includes("sandbox.")) {
    envUrl = process.env.VUE_APP_PRODUCT_IMAGE_URL_SANDBOX;
  } else if (url.includes("tst.")) {
    envUrl = process.env.VUE_APP_PRODUCT_IMAGE_URL_TST;
  } else if (url.includes("acc.")) {
    envUrl = process.env.VUE_APP_PRODUCT_IMAGE_URL_ACC;
  } else if (url.includes("demo.")) {
    envUrl = process.env.VUE_APP_PRODUCT_IMAGE_URL_DEMO;
  } else if (url.includes("localhost")) {
    envUrl = process.env.VUE_APP_PRODUCT_IMAGE_URL_TST;
  } else {
    envUrl = process.env.VUE_APP_PRODUCT_IMAGE_URL_PRD;
  }
  return envUrl;
}

export function getEnvAssetImagePath() {
  let envUrl;
  let url = window.location.href;
  if (url.includes("sandbox.")) {
    envUrl = process.env.VUE_APP_ASSET_IMAGE_URL_SANDBOX;
  } else if (url.includes("tst.")) {
    envUrl = process.env.VUE_APP_ASSET_IMAGE_URL_TST;
  } else if (url.includes("acc.")) {
    envUrl = process.env.VUE_APP_ASSET_IMAGE_URL_ACC;
  } else if (url.includes("demo.")) {
    envUrl = process.env.VUE_APP_ASSET_IMAGE_URL_DEMO;
  } else if (url.includes("localhost")) {
    envUrl = process.env.VUE_APP_ASSET_IMAGE_URL_TST;
  } else {
    envUrl = process.env.VUE_APP_ASSET_IMAGE_URL_PRD;
  }
  return envUrl;
}

export function getEnvNetworkImagePath() {
  let envUrl;
  let url = window.location.href;
  if (url.includes("sandbox.")) {
    envUrl = process.env.VUE_APP_NETWORK_IMAGE_URL_SANDBOX;
  } else if (url.includes("tst.")) {
    envUrl = process.env.VUE_APP_NETWORK_IMAGE_URL_TST;
  } else if (url.includes("acc.")) {
    envUrl = process.env.VUE_APP_NETWORK_IMAGE_URL_ACC;
  } else if (url.includes("demo.")) {
    envUrl = process.env.VUE_APP_NETWORK_IMAGE_URL_DEMO;
  } else if (url.includes("localhost")) {
    envUrl = process.env.VUE_APP_NETWORK_IMAGE_URL_TST;
  } else {
    envUrl = process.env.VUE_APP_NETWORK_IMAGE_URL_PRD;
  }
  return envUrl;
}

export function getSignalRPath() {
  let envUrl;
  let url = window.location.href;
  if (url.includes("sandbox.")) {
    envUrl = process.env.VUE_APP_SIGNALR_URL_SANDBOX;
  } else if (url.includes("tst.")) {
    envUrl = process.env.VUE_APP_SIGNALR_URL_TST;
  } else if (url.includes("acc.")) {
    envUrl = process.env.VUE_APP_SIGNALR_URL_ACC;
  } else if (url.includes("demo.")) {
    envUrl = process.env.VUE_APP_SIGNALR_URL_DEMO;
  } else if (url.includes("prod.")) {
    envUrl = process.env.VUE_APP_SIGNALR_URL_PRD;
  } else if (url.includes("localhost")) {
    envUrl = process.env.VUE_APP_SIGNALR_URL_TST;
  } else {
    envUrl = process.env.VUE_APP_SIGNALR_URL;
  }
  return envUrl;
}

export function getEnvFaviconPath() {
  let envUrl;
  let url = window.location.href;
  if (url.includes("sandbox.")) {
    envUrl = process.env.VUE_APP_FAVICON_IMAGE_URL_SANDBOX;
  } else if (url.includes("tst.")) {
    envUrl = process.env.VUE_APP_FAVICON_IMAGE_URL_TST;
  } else if (url.includes("acc.")) {
    envUrl = process.env.VUE_APP_FAVICON_IMAGE_URL_ACC;
  } else if (url.includes("demo.")) {
    envUrl = process.env.VUE_APP_FAVICON_IMAGE_URL_DEMO;
  } else if (url.includes("localhost")) {
    envUrl = process.env.VUE_APP_FAVICON_IMAGE_URL_TST;
  } else {
    envUrl = process.env.VUE_APP_FAVICON_IMAGE_URL_PRD;
  }
  return envUrl;
}

export function getDocumentationPath() {
  let envUrl;
  let url = window.location.href;
  if (
    url.includes("sandbox.") ||
    url.includes("tst.") ||
    url.includes("acc.") ||
    url.includes("demo.") ||
    url.includes("prod.") ||
    url.includes("prod.") ||
    url.includes("localhost")
  ) {
    envUrl = process.env.VUE_APP_DOCUMENTATION_URL_DEFAULT;
  } else {
    envUrl = process.env.VUE_APP_DOCUMENTATION_URL_LIVE;
  }
  return envUrl;
}

export function checkPath() {
  let path;
  let url = window.location.href;
  if (url.includes("sandbox.")) {
    path = "sandbox";
  } else if (url.includes("tst.")) {
    path = "tst";
  } else if (url.includes("acc.")) {
    path = "acc";
  } else if (url.includes("localhost")) {
    path = "localhost";
  } else {
    path = "prod";
  }
  return path;
}
